import React, { useState } from 'react';
import SearchBar from './searchbar/SearchBar';
import FilterBar from './filterbar/FilterBar';
import './MainBar.css';

/**
 * MainBar Component
 * 
 * The MainBar component serves as a navigation and search control panel, allowing users to switch between a 
 * filter bar and a search bar. It also handles fixed positioning if required.
 * 
 * Key Features:
 * 
 * 1. **Toggle Between Filter and Search Bars**:
 *    - Allows users to switch between the `FilterBar` and `SearchBar` components.
 * 
 * 2. **Fixed Position**:
 *    - Supports fixed positioning for the main bar based on the `fixed` prop.
 * 
 * 3. **State Management**:
 *    - Utilizes React's `useState` hook to manage the visibility of the filter or search bar.
 * 
 * Props:
 * - `fixed`: A boolean that determines if the main bar should be fixed to the top of the screen.
 */

const MainBar = ({ fixed }) => {
  // State to manage the visibility of the FilterBar or SearchBar
  const [useFilterBar, setUseFilterBar] = useState(true);

  // Toggles between showing the FilterBar and SearchBar
  const toggleBar = () => {
    setUseFilterBar(!useFilterBar);
  };

  return (
    <div id='main-bar' className={`${fixed ? 'fixed' : ''}`}>
      {/* Conditionally render FilterBar or SearchBar based on useFilterBar state */}
      {useFilterBar ? <FilterBar toggleBar={toggleBar} fixed={fixed}/> : <SearchBar toggleBar={toggleBar} fixed={fixed}/>}
    </div>
  );
};

export default MainBar;