// © 2024 Fabio Carucci. All Rights Reserved.
import './ContactUsPage.css';
import React from 'react';
import { Helmet } from 'react-helmet'; 
import ContactHeader from '../../assets/ContactHeader.png';

const ContactUsPage = () => {
    return (
        <>
            <Helmet>
                <title>Contact Us | DVC Apartments</title>
                <meta name="description" content="Contact us for any questions regarding DVC Apartments." />
                <meta property="og:title" content="Contact Us | DVC Apartments" />
                <meta property="og:description" content="Reach out to us for questions or bookings at DVC Apartments." />
                <meta property="og:image" content="https://dvcapartments.com/og-image.png" />
                <meta property="og:url" content="https://dvcapartments.com/contact" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Contact Us | DVC Apartments" />
                <meta name="twitter:description" content="Contact us for inquiries about your stays." />
                <meta name="twitter:image" content="https://dvcapartments.com/og-image.png" />
            </Helmet>
            <div className="contactus-page">
                <div className="iframe-container" 
                    style={{backgroundImage: `url(${ContactHeader})`}}
                >
                    <iframe
                        src="https://api.dvc-consulting.it/widget/form/ETVjY0xmh1cpB07NILMg"
                        style={{ width: "100%", border: "none", borderRadius: "0px"}}
                        id="inline-ETVjY0xmh1cpB07NILMg" 
                        data-layout="{'id':'INLINE'}"
                        data-trigger-type="alwaysShow"
                        data-trigger-value=""
                        data-activation-type="alwaysActivated"
                        data-activation-value=""
                        data-deactivation-type="neverDeactivate"
                        data-deactivation-value=""
                        data-form-name="Contact Us Form"
                        data-height="509"
                        data-layout-iframe-id="inline-ETVjY0xmh1cpB07NILMg"
                        data-form-id="ETVjY0xmh1cpB07NILMg"
                        title="Contact Us Form"
                    >
                    </iframe>
                </div>
                <script src="https://api.dvc-consulting.it/js/form_embed.js"></script>
            </div>
        </>
    )
}

export default ContactUsPage;