import React, { useState, useRef, useEffect } from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import ApartmentsList from '../apartments list/ApartmentsList';
import './Optionsbar.css';
import { useTranslation } from 'react-i18next';

const responsive = {
  xxl: { 
    breakpoint: { max: 3000, min: 1700 },
    items: 12,
    partialVisibilityGutter: 50, 
  },
  desktop: {
    breakpoint: { max: 1700, min: 1024 },
    items: 10,
    partialVisibilityGutter: 40
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 6,
    partialVisibilityGutter: 30
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 3,
    partialVisibilityGutter: 30
  }
};

// Optionsbar functional component that displays a carousel of filter options and a list of apartments
/**
 * Optionsbar component renders a carousel with filter options and an apartment list.
 * - Uses a carousel to allow users to select different filter categories.
 * - Displays the selected filter's corresponding apartments list.
 * 
 * @param {Object} props - Component properties
 * @param {boolean} props.fixed - Determines if the carousel should have a fixed position
 * @param {boolean} props.isMapView - Determines if the view is in map mode
 * @returns {JSX.Element} The rendered component
 */

export default function Optionsbar({ fixed, isMapView }) {
  const {t} = useTranslation();
  const listRef = useRef(null);

  // Define the tab options to be displayed in the carousel
  const tabOptions = [
    { eventKey: "All-location", title: t('all_location') },
    { eventKey: "Centro-città", title: t('city_centre') },
    { eventKey: "Borgo-storico", title: t('historic_village') },
    { eventKey: "Pets-friendly", title: t('pets_friendly') },
    { eventKey: "Con-vista", title: t('with_view') },
    { eventKey: "Con-giardino", title: t('with_garden') },
    { eventKey: "Per-famiglie", title: t('for_family') },
    { eventKey: "Tra-le-vigne", title: t('among_the_vineyards') },
    { eventKey: "Lusso", title: t('luxury') },
    { eventKey: "Spaziosi", title: t('spacious') },
    { eventKey: "Smart-working", title: t('smart_working') },
    { eventKey: "Per-coppie", title: t('for_couples') },
  ];

  // State hook to track the currently selected tab option
  const [selectedOption, setSelectedOption] = useState("All-location");
  const [isUserAction, setIsUserAction] = useState(false); // Flag to track if the change is from user interaction

  useEffect(() => {
    if (isUserAction && listRef.current) {
      const yOffset = -144;
      const y = listRef.current.getBoundingClientRect().top + window.scrollY + yOffset;
      window.scrollTo({ top: y, behavior: 'smooth' });
      setIsUserAction(false); // Reset after scrolling
    }
  }, [selectedOption, isUserAction]);

  const handleTabClick = (eventKey) => {
    setSelectedOption(eventKey);
    setIsUserAction(true); // Set flag when user clicks
  };

  return (
    <div className='options-bar-container'>
      <Carousel
        additionalTransfrom={0}
        arrows
        autoPlaySpeed={3000}
        centerMode={false}
        className={`${fixed ? 'fixed-carousel' : ''}`}
        dotListClass=""
        draggable
        focusOnSelect={false}
        infinite={false}
        itemClass=""
        keyBoardControl
        minimumTouchDrag={80}
        pauseOnHover
        renderArrowsWhenDisabled={false}
        renderButtonGroupOutside={false}
        renderDotsOutside={false}
        responsive={responsive}
        rewind={false}
        rewindWithAnimation={false}
        rtl={false}
        shouldResetAutoplay
        showDots={false}
        sliderClass=""
        slidesToSlide={2}
        swipeable
      >
        {tabOptions.map((option) => (
          // Render each tab option inside the carousel and handle click to update selected option
          <div 
            key={option.eventKey} 
            data-testid={`tab-${option.eventKey}`}
            onClick={() => handleTabClick(option.eventKey)} 
            className={`tab-item ${selectedOption === option.eventKey ? 'active' : ''}`}
          >
            {option.title}
          </div>
        ))}
      </Carousel>

      <div ref={listRef}>
        <ApartmentsList selectedOption={selectedOption} isMapView={isMapView} />
      </div>
    </div>
  );
}