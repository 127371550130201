import React, { useEffect, useState } from 'react';
import './WeatherBanner.css';
import { useTranslation } from 'react-i18next';

function WeatherBanner({ lat, lon }) {
    const { i18n } = useTranslation(); // Hook for translations
    const currentLang = i18n.language.split('-')[0] || 'en'; // Get the current language or default to English
    const [weatherData, setWeatherData] = useState(null);

    async function fetchWeatherData(lat, lon, currentLang) {
        const apiKey = process.env.REACT_APP_OPENWEATHERMAP_API_KEY;
        const url = `https://api.openweathermap.org/data/2.5/weather?lat=${lat}&lon=${lon}&appid=${apiKey}&lang=${currentLang}`;
    
        try {
            const response = await fetch(url);
            if (!response.ok) {
                throw new Error(`Errore HTTP: ${response.status}`);
            }
            const data = await response.json();
    
            // Controllo se i dati meteo sono validi
            if (data.cod !== 200) {
                throw new Error(`Errore API: ${data.message}`);
            }
    
            data.main.temp = (data.main.temp - 273.15).toFixed(1);
            data.main.feels_like = (data.main.feels_like - 273.15).toFixed(1);
            data.main.temp_min = (data.main.temp_min - 273.15).toFixed(1);
            data.main.temp_max = (data.main.temp_max - 273.15).toFixed(1);
    
            data.sys.sunrise = new Date(data.sys.sunrise * 1000).toLocaleTimeString();
            data.sys.sunset = new Date(data.sys.sunset * 1000).toLocaleTimeString();
    
            setWeatherData(data);
        } catch (error) {
            console.error("Errore nel recupero dei dati meteo:", error);
            // Non mostriamo nulla all'utente se c'è un errore
            setWeatherData(null); // Assicurati di impostare il valore a null per gestire correttamente la condizione
        }
    }

    useEffect(() => {
        fetchWeatherData(lat, lon, currentLang);
    }, [lat, lon, currentLang]);

    // Non mostriamo nulla se non ci sono dati meteo
    if (!weatherData) return null;

    const iconUrl = `https://openweathermap.org/img/wn/${weatherData.weather[0].icon}@2x.png`;

    return (
        <div className="weather-main">
            <img src={iconUrl} alt="Weather Icon" className="weather-icon" />
            <div>
                <div className="weather-info">{weatherData.weather[0].description}</div>
                <div className="weather-temp">{weatherData.main.temp}°C</div>
            </div>
        </div>
    );
}

export default WeatherBanner;