import React from 'react';
import { FiSearch } from "react-icons/fi";
import './SearchBar.css'; // Import the CSS file for styling

/**
 * SearchBar Component
 * 
 * The SearchBar component provides a text input field for users to enter search queries
 * and a button with a search icon to initiate the search. It is typically used for searching
 * through content or filtering results based on user input.
 * 
 * Key Features:
 * 
 * 1. **Search Input Field**:
 *    - A text input field where users can type their search queries. The placeholder text provides an example of a typical search query.
 * 
 * 2. **Search Button**:
 *    - A button with a search icon (`FiSearch` from `react-icons`) to trigger the search action.
 * 
 * 3. **Accessibility**:
 *    - Uses `aria-label` attributes to enhance accessibility by providing descriptive labels for screen readers.
 * 
 * Usage:
 * - This component can be used in search interfaces, filters, or any place where users need to enter and submit search queries.
 * 
 * Example:
 * ```jsx
 * <SearchBar />
 * ```
 */
const SearchBar = () => {
  return (
    <div className="search-bar">
      {/* Input field for entering search queries */}
      <input
        type="text"
        placeholder="&quot;home in california for 3 people from Jul 19 to Jul 24&quot;" // Example search query
        aria-label="search" // Accessible label for screen readers
      />
      {/* Button to trigger the search */}
      <button type="submit" aria-label="search">
        <FiSearch className='text-lg' /> {/* Search icon */}
      </button>
    </div>
  );
};

export default SearchBar;