// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from 'firebase/firestore';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAk3ev90a3Q149PgK9HUA982Y-B6S2rtXg",
  authDomain: "dvc-apartments-2024.firebaseapp.com",
  projectId: "dvc-apartments-2024",
  storageBucket: "dvc-apartments-2024.appspot.com",
  messagingSenderId: "462627850207",
  appId: "1:462627850207:web:e59def49e1ed7d706ae66a",
  measurementId: "G-GEC6RGGCMT"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export { db };