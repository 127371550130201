import React, { useRef, useEffect, useContext } from 'react';
import { Row, Col, Button, FormControl } from 'react-bootstrap';
import './GuestsDropdown.css';
import { FiltersContext } from '../../../../context/FiltersContext';
import { useTranslation } from 'react-i18next';

/**
 * GuestsDropdown Component
 * 
 * The GuestsDropdown component allows users to select and adjust the number of guests (adults, children, infants, and pets).
 * It provides a user-friendly interface to increment and decrement the counts for each category of guests and includes 
 * functionality for clearing the selections and submitting the chosen values.
 * 
 * Key Features:
 * 
 * 1. **Guest Counters**:
 *    - Increment and decrement the counts for adults, children, infants, and pets.
 * 
 * 2. **Click Outside to Close**:
 *    - Closes the dropdown when clicking outside of it.
 * 
 * 3. **Clear and Submit**:
 *    - Includes buttons to clear all guest counts and submit the selections.
 * 
 * Props:
 * - `showDropdown`: Boolean indicating if the dropdown is visible.
 * - `setShowDropdown`: Function to control the visibility of the dropdown.
 * - `totalGuests`: Total number of guests selected.
 */

const GuestsDropdown = ({ showDropdown, setShowDropdown, totalGuests }) => {
    const { t } = useTranslation();
    // useRef is used to keep a reference to the dropdown container
    const dropdownRef = useRef(null);

    // Using context to get and set the state of guests (adults, children, infants, and pets)
    const { adults, setAdults, childrens, setChildrens, infants, setInfants, pets, setPets } = useContext(FiltersContext);

    useEffect(() => {
        // Function to handle clicks outside the dropdown
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target) ) {
                setShowDropdown(false);
            }
        };

        // Adding event listener when the dropdown is shown
        if (showDropdown) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        // Cleanup function to remove event listener
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [showDropdown, setShowDropdown]);

    // Functions to increment and decrement guest counts
    const increment = (setter, value) => () => setter(value + 1);
    const decrement = (setter, value) => () => setter(value > 0 ? value - 1 : 0);

    return (
        <div ref={dropdownRef} className="guests-dropdown">
            {/* Guest Selection Rows */}
            <Row className="px-4 mt-3 mb-0">
                {[
                    { label: t('adults'), age: `${t('age')} 13+`, count: adults, setCount: setAdults },
                    { label: t('children'), age: `${t('age')} 2-12`, count: childrens, setCount: setChildrens },
                    { label: t('infants'), age: `${t('under')} 2`, count: infants, setCount: setInfants },
                    { label: t('pets'), age: '', count: pets, setCount: setPets },
                ].map(({ label, age, count, setCount }) => (
                    // Mapping through the guest categories to create rows for each
                    <Row key={label} className="w-100 mb-3 pe-0">
                        <Col xs={6} className="guests-label">
                            <span className="fw-bold">{label}</span>
                            {age && <small>{age}</small>}
                        </Col>
                        <Col xs={6} className="guests-handler">
                            <Button onClick={decrement(setCount, count)} disabled={count === 0}>-</Button>
                            <FormControl 
                                type="text" 
                                value={count} 
                                readOnly 
                                className="text-center mx-2" 
                                style={{ width: '40px' }}
                            />
                            <Button onClick={increment(setCount, count)}>+</Button>
                        </Col>
                    </Row>
                ))}
            </Row>
            {/* Action Buttons */}
            <Row className="mt-2 px-4 justify-content-between d-none d-lg-flex">
                <Col xs="auto">
                    <Button 
                        className="rounded-3 clear-btn" 
                        onClick={() => {
                            // Resetting all guest counts to 0 when the "Clear" button is clicked
                            setAdults(0);
                            setChildrens(0);
                            setInfants(0);
                            setPets(0);
                        }}
                    >
                        {t('clear')}
                    </Button>
                </Col>
                <Col xs="auto">
                    <Button 
                        className="rounded-3 search-btn" 
                        onClick={(e) => {
                            e.stopPropagation(); 
                            setShowDropdown(false)
                        }}
                        disabled={totalGuests === 0}
                    >
                        {t('search')}
                    </Button>
                </Col>
            </Row>
        </div>
    );
};

export default GuestsDropdown;