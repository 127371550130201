// © 2024 Fabio Carucci. All Rights Reserved.
import React from 'react';
import './MaintenancePage.css';
import { useTranslation } from 'react-i18next';

/**
 * MaintenancePage Component
 * 
 * The MaintenancePage component is displayed to users when the application or website
 * is undergoing maintenance. It provides a simple message to inform users about the 
 * maintenance status and indicates that the site will be back shortly.
 * 
 * Key Features:
 * 
 * 1. **Translation Support**:
 *    - Uses `react-i18next` for internationalization to display messages in different languages.
 * 
 * 2. **Simple Layout**:
 *    - Displays a heading and a message to inform users about the maintenance status.
 * 
 * 3. **Styling**:
 *    - Applies styles from `MaintenancePage.css` to control the appearance of the maintenance message.
 * 
 * Translations:
 * - `undergoing_maintenance`: A message indicating that the site is under maintenance.
 * - `back_shortly`: A message reassuring users that the site will be available soon.
 * 
 * Usage:
 * - This component should be used as a placeholder page during maintenance periods.
 * 
 * Example:
 * ```jsx
 * <MaintenancePage />
 * ```
 */
const MaintenancePage = () => {
  // Translation hook to access internationalized strings
  const { t } = useTranslation();

  return (
    <div className="maintenance-container">
      <div className="maintenance-content">
        {/* Heading indicating the site is under maintenance */}
        <h1>{t('undergoing_maintenance')}</h1>
        {/* Message indicating the site will be back shortly */}
        <p>{t('back_shortly')}</p>
      </div>
    </div>
  );
};

export default MaintenancePage;