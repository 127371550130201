import React, { createContext, useState, useEffect, useContext } from 'react';
import { collection, getDocs } from 'firebase/firestore'; 
import { db } from '../firebase';

// Create the context
const ApartmentsContext = createContext();

/**
 * ApartmentsProvider Component
 * 
 * The ApartmentsProvider component provides a context to manage the state of apartments
 * across the application. It allows components within its provider to access and modify 
 * the list of apartments fetched from Firestore.
 * 
 * Key Features:
 * 
 * 1. **State Management**:
 *    - Manages the list of apartments using React's `useState`.
 * 
 * 2. **Context API Integration**:
 *    - Utilizes React's Context API to provide the apartment data and fetch function to all components
 *      within the provider's tree.
 * 
 * 3. **State Variables**:
 *    - `apartments`: An array containing the list of apartments fetched from Firestore.
 *    - `setApartments`: Function to update the list of apartments.
 * 
 * Props:
 * - `children`: The components that will have access to the apartments context values.
 */
export const ApartmentsProvider = ({ children }) => {
  // State to manage the list of apartments
  const [apartments, setApartments] = useState([]);

  useEffect(() => {
    const fetchApartments = async () => {
      const querySnapshot = await getDocs(collection(db, 'apartments'));
      const fetchedApartments = [];
      querySnapshot.forEach(doc => {
        fetchedApartments.push({ id: doc.id, ...doc.data() });
      });
      setApartments(fetchedApartments);
    };

    fetchApartments();
  }, []);

  return (
    <ApartmentsContext.Provider value={{ apartments, setApartments }}>
      {children}
    </ApartmentsContext.Provider>
  );
};

// Custom hook for using the Apartments context
/**
 * useApartments Hook
 * 
 * The `useApartments` hook provides a convenient way to access the Apartments context values 
 * in functional components. It returns the current context value, which includes the list 
 * of apartments and a function to update it.
 * 
 * Returns:
 * - An object containing `apartments` and `setApartments`.
 */
export const useApartments = () => useContext(ApartmentsContext);