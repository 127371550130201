import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

// Import translation files directly
import enTranslation from './locales/en.json';
import itTranslation from './locales/it.json';
import frTranslation from './locales/fr.json';
import deTranslation from './locales/de.json';
import esTranslation from './locales/es.json';

/**
 * i18next Configuration
 * 
 * This file sets up and configures `i18next` for internationalization (i18n) in a React application.
 * It initializes `i18next` with language detection capabilities and loads translation resources for
 * multiple languages. The configuration also sets up language detection and fallback options.
 * 
 * Key Features:
 * 
 * 1. **Language Detection**:
 *    - Uses `i18next-browser-languagedetector` to automatically detect the user's language from various sources.
 * 
 * 2. **Translation Resources**:
 *    - Loads translation files directly for English, Italian, French, German, and Spanish.
 * 
 * 3. **Fallback Language**:
 *    - Specifies English (`en`) as the default language if no other language is detected.
 * 
 * 4. **Interpolation Settings**:
 *    - Configures interpolation settings to not escape HTML values (set `escapeValue` to false).
 * 
 * 5. **Language Detection Configuration**:
 *    - Sets the order and caches for language detection, including query string, cookies, local storage, and navigator.
 * 
 * Initialization:
 * - `i18next` is configured with `initReactI18next` to integrate with React.
 * - The `resources` object contains the translations for supported languages.
 */
i18next
  .use(LanguageDetector) // Use the language detector to determine user's language
  .use(initReactI18next) // Integrate i18next with React
  .init({
    fallbackLng: 'en', // Default language if no other language is found
    interpolation: {
      escapeValue: false // Disable escaping of HTML values
    },
    resources: {
      en: {
        translation: enTranslation // English translations
      },
      it: {
        translation: itTranslation // Italian translations
      },
      fr: {
        translation: frTranslation // French translations
      },
      de: {
        translation: deTranslation // German translations
      },
      es: {
        translation: esTranslation // Spanish translations
      }
    },
    detection: {
      // Configuration for language detection
      order: ['querystring', 'cookie', 'localStorage', 'navigator'], // Detection order
      caches: ['cookie'], // Cache the selected language in a cookie
    }
  });

export default i18next;