import React, { useRef, useEffect } from 'react';
import { DayPicker } from 'react-day-picker';
import { Button, Row, Col } from 'react-bootstrap';
import 'react-day-picker/dist/style.css';
import './DatePicker.css';
import { useTranslation } from 'react-i18next';
import { enGB, it, fr, de, es } from 'date-fns/locale';

/**
 * DatePicker Component
 * 
 * The DatePicker component allows users to select a date range using the DayPicker component.
 * It provides functionality to handle date selection, clear the selected range, and proceed with further actions.
 * 
 * Key Features:
 * 
 * 1. **Date Range Selection**:
 *    - Allows users to select a range of dates.
 * 
 * 2. **Localization**:
 *    - Supports multiple languages through date-fns locales.
 * 
 * 3. **Click Outside to Close**:
 *    - Closes the date picker when clicking outside of it.
 * 
 * 4. **Action Buttons**:
 *    - Includes buttons to clear the selected range and to continue to the next step.
 * 
 * Props:
 * - `showDatePicker`: Boolean indicating if the date picker is visible.
 * - `setShowDatePicker`: Function to control the visibility of the date picker.
 * - `onDateSelect`: Callback function to handle date range selection.
 * - `isRangeSelected`: Boolean indicating if a date range is selected.
 * - `handleClear`: Function to clear the selected date range.
 * - `range`: The currently selected date range.
 * - `toggleLocationDropdown`: Function to toggle the location dropdown.
 * - `month`: Number of months to display in the date picker.
 */

const DatePicker = ({ showDatePicker, setShowDatePicker, onDateSelect, isRangeSelected, handleClear, range, toggleLocationDropdown, month }) => {

    const { t, i18n } = useTranslation();

    // Reference to the date picker container
    const datePickerRef = useRef(null);

    // Maps of languages of date-fns
    const locales = {
        "en-EN": enGB,
        "it-IT": it,
        "fr-FR": fr,
        "de-DE": de,
        "es-ES": es
    };

    // Get the current locale based on i18n language or default to English
    const currentLocale = locales[i18n.language] || enGB;

    // Function to handle date selection
    const handleSelect = (selectedRange) => {
        onDateSelect(selectedRange);
    };

    useEffect(() => {
        // Add or remove event listener based on showDatePicker state
        if (showDatePicker) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        // Cleanup event listener on component unmount or when showDatePicker changes
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [showDatePicker]);

    // Function to handle clicks outside the date picker
    const handleClickOutside = (event) => {
        if (datePickerRef.current && !datePickerRef.current.contains(event.target)) {
            setShowDatePicker(false);   
        }
    };

    // Function to handle the "Continue" button click
    const handleContinueClick = (event) => {
        event.stopPropagation(); // Prevent event propagation
        setShowDatePicker(false); // Hide the date picker
        toggleLocationDropdown(); // Toggle the location dropdown
    };

    return (
        <div className="date-picker" ref={datePickerRef}>
            {/* DayPicker component to select a date range */}
            <DayPicker
                mode="range"
                selected={range}
                onSelect={handleSelect}
                numberOfMonths={month}
                disabled={[{ before: new Date() }]}
                locale={currentLocale}
            />
            <Row className="mt-2 px-4 justify-content-between d-none d-lg-flex">
                <Col xs="auto">
                    {/* Button to clear the selected date range */}
                    <Button className='rounded-3 clear-btn' onClick={handleClear}>{t('clear')}</Button>
                </Col>
                <Col xs="auto">
                    {/* Button to continue, disabled if no range is selected */}
                    <Button 
                        className='rounded-3 continue-btn' 
                        disabled={!isRangeSelected}
                        onClick={handleContinueClick}
                    >{t('continue')}</Button>
                </Col>
            </Row>
        </div>
    );
};

export default DatePicker;