import React, { createContext, useState, useContext } from 'react';

// Create the context
const LiveChatContext = createContext();

/**
 * LiveChatProvider Component
 * 
 * The LiveChatProvider component provides a context to manage the visibility of a live chat feature 
 * across the application. It allows components within its provider to access and modify the state 
 * indicating whether the live chat window should be shown.
 * 
 * Key Features:
 * 
 * 1. **State Management**:
 *    - Manages the visibility state of the live chat window using React's `useState`.
 * 
 * 2. **Context API Integration**:
 *    - Utilizes React's Context API to provide the visibility state and updater function to all components
 *      within the provider's tree.
 * 
 * 3. **State Variables**:
 *    - `showLiveChat`: A boolean indicating whether the live chat window is visible.
 *    - `setShowLiveChat`: Function to update the visibility state of the live chat window.
 * 
 * Props:
 * - `children`: The components that will have access to the live chat context values.
 */
export const LiveChatProvider = ({ children }) => {
  // State to manage the visibility of the live chat window
  const [showLiveChat, setShowLiveChat] = useState(false);

  return (
    <LiveChatContext.Provider value={{ showLiveChat, setShowLiveChat }}>
      {children}
    </LiveChatContext.Provider>
  );
};

// Custom hook for using the LiveChat context
/**
 * useLiveChat Hook
 * 
 * The `useLiveChat` hook provides a convenient way to access the LiveChat context values in functional 
 * components. It returns the current context value, which includes the visibility state of the live chat 
 * window and a function to update it.
 * 
 * Returns:
 * - An object containing `showLiveChat` and `setShowLiveChat`.
 */
export const useLiveChat = () => useContext(LiveChatContext);