import React, { useState } from 'react';
import './ShareModal.css';
import { Modal, Button, Popover, OverlayTrigger } from 'react-bootstrap';
import { EmailShareButton, FacebookShareButton, LinkedinShareButton, TelegramShareButton, TwitterShareButton, WhatsappShareButton, EmailIcon, FacebookIcon, LinkedinIcon, TelegramIcon, XIcon, WhatsappIcon } from 'react-share';
import { useTranslation } from 'react-i18next';

/**
 * ShareModal Component
 * 
 * The ShareModal component is a modal dialog that allows users to share a specified URL via various social media platforms 
 * or email. It also provides an option to copy the link to the clipboard with feedback.
 * 
 * Key Features:
 * 
 * 1. **Social Media Sharing**:
 *    - Provides buttons to share the URL on Facebook, Twitter, Telegram, WhatsApp, LinkedIn, and via email.
 * 
 * 2. **Copy Link Functionality**:
 *    - Allows users to copy the URL to the clipboard with a confirmation popover that appears briefly.
 * 
 * 3. **Internationalization**:
 *    - Uses `react-i18next` for localization, enabling dynamic text translation based on the user's language.
 * 
 * Props:
 * - `show`: A boolean that controls the visibility of the modal.
 * - `handleClose`: A function to close the modal.
 * - `url`: The URL to be shared or copied.
 */

const ShareModal = ({ show, handleClose, url }) => {
  // Translation hook for internationalization
  const { t } = useTranslation();
  
  // State to manage the visibility of the popover
  const [showPopover, setShowPopover] = useState(false);

  // Function to handle link copying to the clipboard
  const handleCopyLink = () => {
    navigator.clipboard.writeText(url); // Copy the URL to the clipboard
    setShowPopover(true); // Show the popover
    setTimeout(() => {
      setShowPopover(false); // Hide the popover after 2 seconds
    }, 2000);
  };

  // Render nothing if the modal is not visible
  if (!show) return null;

  return (
    <Modal className='referafriend-modal' show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>{t('refer_a_friend')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="text-center mb-3">
          <p>{t('share_link_with_friends')}</p>
          <div className="d-flex justify-content-center mb-4">
            {/* Social media share buttons */}
            <FacebookShareButton aria-label="Facebook share" url={url} className="me-2">
              <FacebookIcon size={32} round />
            </FacebookShareButton>
            <TwitterShareButton aria-label="Twitter share" url={url} className="me-2">
              <XIcon size={32} round />
            </TwitterShareButton>
            <TelegramShareButton aria-label="Telegram share" url={url} className="me-2">
              <TelegramIcon size={32} round />
            </TelegramShareButton>
            <WhatsappShareButton aria-label="WhatsApp share" url={url} className="me-2">
              <WhatsappIcon size={32} round />
            </WhatsappShareButton>
            <LinkedinShareButton aria-label="LinkedIn share" url={url} className="me-2">
              <LinkedinIcon size={32} round />
            </LinkedinShareButton>
            <EmailShareButton aria-label="Email share" url={url}>
              <EmailIcon size={32} round />
            </EmailShareButton>
          </div>
          <OverlayTrigger
            show={showPopover}
            placement="top"
            overlay={<Popover id="popover-basic"><Popover.Body>{t('link_copied')}</Popover.Body></Popover>}
          >
            <Button className='copy-btn' onClick={handleCopyLink} aria-label="Copy_link">
              {t('copy_link')}
            </Button>
          </OverlayTrigger>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ShareModal;