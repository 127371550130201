import React, { useState, useContext } from 'react';
import { FiSearch, FiCalendar } from "react-icons/fi";
import { GrLocation } from "react-icons/gr";
import { IoPeopleOutline, IoCloseOutline, IoFilterCircleOutline } from "react-icons/io5";
import DatePicker from './date picker/DatePicker';
import LocationDropdown from './location dropdown/LocationDropdown';
import './FilterBar.css'; 
import { Row, Col, Accordion, Button, Modal } from 'react-bootstrap';
import GuestsDropdown from './guests dropdown/GuestsDropdown';
import { FiltersContext } from '../../../context/FiltersContext';
import logo from '../../../assets/logo.png';
import { useTranslation } from 'react-i18next';

/**
 * FilterBar Component
 * 
 * The FilterBar component provides an interface for users to filter search results by date, location, and guest count.
 * It features a desktop and mobile view with options to select dates, locations, and guest numbers.
 * 
 * Key Features:
 * 
 * 1. **Date Selection**:
 *    - Users can select and clear date ranges for their search.
 * 
 * 2. **Location Selection**:
 *    - Users can choose and clear location filters.
 * 
 * 3. **Guest Count**:
 *    - Users can specify the number of adults, children, and infants.
 * 
 * 4. **Mobile and Desktop Views**:
 *    - Provides a responsive design with a modal for mobile devices.
 * 
 * 5. **Context Integration**:
 *    - Uses React Context to manage filter states across the application.
 * 
 * Props:
 * - `toggleBar`: Function to toggle between the filter bar and search bar.
 * - `fixed`: Boolean indicating if the filter bar should be fixed to the top.
 */

const FilterBar = ({ toggleBar, fixed }) => {
    const { t } = useTranslation();

    // State hooks for date picker
    const [showDatePicker, setShowDatePicker] = useState(false);
    const [isRangeSelected, setIsRangeSelected] = useState(false);
    const [range, setRange] = useState({ from: undefined, to: undefined });

    // State hooks for location dropdown
    const [showLocationDropdown, setShowLocationDropdown] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');

    // Context values for selected states and guests
    const { selectedStates, setSelectedStates, adults, setAdults, childrens, setChildrens, infants, setInfants, setPets } = useContext(FiltersContext);

    // State hooks for guests dropdown
    const [showGuestDropdown, setShowGuestsDropdown] = useState(false);

    // State hook for modal visibility in mobile view
    const [showModal, setShowModal] = useState(false);

    // Function to handle date selection
    const handleDateSelect = (selectedRange) => {
        if (range.from && range.to) {
            setRange({ from: undefined, to: undefined });
            setIsRangeSelected(false);
        } else if (range.from || range.to) {
            setRange(selectedRange);
            setIsRangeSelected(true); 
        } else {
            setRange(selectedRange);
            setIsRangeSelected(false);   
        }
    }; 

    // Function to clear date selection
    const handleDateClear = () => {
        setRange({});
        setIsRangeSelected(false);
        setShowDatePicker(false);
    };

    // Function to clear location selection
    const handleLocationClear = () => {
        setSearchTerm('');
        setSelectedStates([]);
    }

    // Function to clear guest selection
    const handleGuestsClear = () => {
        setAdults(0);
        setChildrens(0);
        setInfants(0);
        setPets(0);
    }

    // Function to format date for display
    const formatDate = (date, showMonth = true) => {
        const options = { month: 'short', day: 'numeric' };
        const dateString = new Date(date).toLocaleDateString(undefined, options);
        if (!showMonth) {
            return dateString.split(' ')[0]; // return only the day part
        }
        return dateString;
    };

    // Function to format date range for display
    const formatRange = (range) => {
        const fromDate = formatDate(range.from);
        const toDate = formatDate(range.to, false); // don't show the month for the 'to' date
        return `${fromDate.split(' ')[1]} ${fromDate.split(' ')[0]} - ${toDate}`;
    };

    // Total guests calculation
    const totalGuests = adults + childrens + infants;

    // Toggle location dropdown
    const toggleLocationDropdown = () => {
        setShowLocationDropdown(true);
    }

    // Toggle guests dropdown
    const toggleGuestsDropdown = () => {
        setShowGuestsDropdown(true);
    }

    // Modal open and close handlers
    const handleOpenModal = () => setShowModal(true);
    const handleCloseModal = () => setShowModal(false);

    return (
        <div className="filter-bar-container">
            <div className={`filter-bar ${fixed ? 'fixed' : ''}`}>
                <Row className="filter-items">
                    {/* Date Picker Column */}
                    <Col xs={4} className="filter-item" style={{borderBottomLeftRadius: !fixed ? null : "6px"}}>
                        <div className="filter-content" onClick={(e) => setShowDatePicker(!showDatePicker)}>
                            <FiCalendar className='text-lg filter-icon' />
                            <div className="filter-text-container">
                                {!fixed && <span className="filter-label">{t('when')}</span>}
                                {!isRangeSelected ? (
                                    <span className="filter-text">{!fixed ? `${t('add_dates')}` : `${t('whenever')}`}</span>
                                ) : (
                                    <Row className='filter-text'>
                                        <Col xs={8}>
                                            <span>{formatRange(range)}</span>
                                        </Col>
                                        <Col xs={4}>
                                            <span className='filter-icon' onClick={handleDateClear}><IoCloseOutline /></span>
                                        </Col>
                                    </Row>
                                )}
                            </div>
                        </div>
                        {showDatePicker && (                          
                            <DatePicker 
                                showDatePicker={showDatePicker}
                                setShowDatePicker={setShowDatePicker}
                                onDateSelect={handleDateSelect} 
                                isRangeSelected={isRangeSelected} 
                                handleClear={handleDateClear} 
                                range={range} 
                                toggleLocationDropdown={toggleLocationDropdown}
                                month={2}
                            />
                        )}
                    </Col>
                    {/* Location Dropdown Column */}
                    <Col xs={4} className="filter-item" >
                        <div className="filter-content" onClick={() => setShowLocationDropdown(true)}>
                            <GrLocation className='text-lg filter-icon' />
                            <div className="filter-text-container">
                                {!fixed && <span className="filter-label">{t('where')}</span>}
                                {selectedStates.length === 0 ? (
                                    <span className="filter-text">{!fixed ? `${t('add_location')}` : `${t('wherever')}`}</span>
                                ) : (
                                    <Row className='filter-text location-filter-text'>
                                        <Col xs={9}>
                                            <div>
                                                {selectedStates.length <= 2 ? (
                                                    selectedStates.map((state, index) => (
                                                        <span key={index}>{state}{index < selectedStates.length - 1 ? ', ' : ''}</span>
                                                    ))
                                                ) : (
                                                    <span>{selectedStates[0]} + {selectedStates.length - 1}</span>
                                                )}
                                            </div>    
                                        </Col>
                                        <Col xs={3}>
                                            <span className='filter-icon' onClick={handleLocationClear}>
                                                <IoCloseOutline />
                                            </span>
                                        </Col>
                                    </Row>
                                )}
                            </div> 
                        </div>
                        {showLocationDropdown && (
                            <LocationDropdown 
                                showDropdown={showLocationDropdown} 
                                setShowDropdown={setShowLocationDropdown} 
                                searchTerm={searchTerm}
                                setSearchTerm={setSearchTerm}
                                toggleGuestsDropdown={toggleGuestsDropdown}
                            />
                        )}
                    </Col>
                    {/* Guests Dropdown Column */}
                    <Col xs={4} className='filter-item' style={{borderBottomRightRadius: !fixed ? null : "6px"}} >
                        <div className="filter-content" onClick={() => setShowGuestsDropdown(true)}>
                            <IoPeopleOutline className='text-lg filter-icon' />
                            <div className="filter-text-container">
                                {!fixed && <span className="filter-label">{t('who')}</span>}
                                {totalGuests === 0 ? (
                                    <span className="filter-text">{!fixed ? `${t('add_guests')}` : `${t('whoever')}`}</span>
                                ) : (
                                    <Row className='filter-text'>
                                        <Col xs={2}>
                                            <span>{totalGuests}</span>
                                        </Col>
                                        <Col xs={4}>
                                            <span className='filter-icon' onClick={handleGuestsClear}><IoCloseOutline /></span>
                                        </Col>
                                    </Row>
                                )}
                            </div>
                        </div>
                        {showGuestDropdown && (
                            <GuestsDropdown
                                showDropdown={showGuestDropdown}
                                setShowDropdown={setShowGuestsDropdown}
                                totalGuests={totalGuests}
                            />
                        )}
                    </Col>
                    <button type="submit" aria-label="search" className="filter-search-button" style={{padding: !fixed ? "8px" : "4px"}}>
                        <FiSearch className='text-lg' />
                    </button>
                </Row>
                {/* Optionally, a switch button for another search mode */}
                {/* {!fixed && <div className="switch-button-container">
                    <button onClick={toggleBar} className="switch-button">
                        <FaArrowsRotate />
                        Search using <span>DVC AI</span>
                    </button>
                </div>} */}
            </div>

            {/* Mobile Filter Bar */}
            <div className="mobile-filter-bar">
                <Row className='align-items-center justify-content-center'>
                    <Col xs={2} style={{paddingLeft: "0", paddingRight: "8px"}}>
                        <img src={logo} width="100%" alt="logo" style={{ objectFit: "contain" }} />
                    </Col>
                    <Col xs={10} className='d-flex align-items-center' style={{ whiteSpace: "nowrap", fontWeight: "bold", backgroundColor: "var(--second-background-color)", padding: "10px 15px", borderRadius: "8px"}} onClick={handleOpenModal}>
                        <IoFilterCircleOutline style={{fontSize: "20pt"}}/>
                        <div style={{paddingLeft: "10px"}}>
                            <div>{t('find_your_houses')}</div>
                            <Row className="preferences">
                                <Col xs={4}>
                                    {isRangeSelected ? formatRange(range) : `${t('whenever')}`}
                                </Col>
                                <Col xs={4} className="text-truncate">
                                    {selectedStates.length > 0 ? (
                                        <div>
                                            {selectedStates.length <= 2 ? (
                                                selectedStates.map((state, index) => (
                                                    <span key={index} className="state-name">
                                                        {state}{index < selectedStates.length - 1 ? ', ' : ''}
                                                    </span>
                                                ))
                                            ) : (
                                                <span>{selectedStates[0]} + {selectedStates.length - 1}</span>
                                            )}
                                        </div>
                                    ) : `${t('wherever')}`}
                                </Col>
                                <Col xs={4}>
                                    {totalGuests > 0 ? totalGuests : `${t('whoever')}`}
                                </Col>                        
                            </Row>
                        </div>
                    </Col>
                </Row>
            </div>
            <Modal className='filter-modal' show={showModal} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>{t('filters')}</Modal.Title>
                </Modal.Header>    
                <Modal.Body>
                    <Accordion defaultActiveKey="0">
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>
                                <div className='w-100 d-flex justify-content-between'>
                                    <div>{t('when')}</div>
                                    {!isRangeSelected ? (
                                        <span className="filter-text">{t('whenever')}</span>
                                    ) : (
                                        <Row className='filter-text'>
                                            <Col xs={8}>
                                                <span>{formatRange(range)}</span>
                                            </Col>
                                            <Col xs={4}>
                                                <span className='filter-icon' onClick={(e) => {handleDateClear(); e.stopPropagation()}}><IoCloseOutline /></span>
                                            </Col>
                                        </Row>
                                    )}
                                </div>
                            </Accordion.Header>
                            <Accordion.Body>
                                <DatePicker 
                                    showDatePicker={true}
                                    setShowDatePicker={setShowDatePicker}
                                    onDateSelect={handleDateSelect} 
                                    isRangeSelected={isRangeSelected} 
                                    handleClear={handleDateClear} 
                                    range={range}
                                    month={1} 
                                />
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="1">
                            <Accordion.Header>
                                <div className='w-100 d-flex justify-content-between'>
                                    <div>{t('where')}</div>
                                    {selectedStates.length === 0 ? (
                                        <span className="filter-text">{t('wherever')}</span>
                                    ) : (
                                        <Row className='filter-text location-filter-text'>
                                            <Col xs={9}>
                                                <div>
                                                    {selectedStates.length <= 2 ? (
                                                        selectedStates.map((state, index) => (
                                                            <span key={index}>{state}{index < selectedStates.length - 1 ? ', ' : ''}</span>
                                                        ))
                                                    ) : (
                                                        <span>{selectedStates[0]} + {selectedStates.length - 1}</span>
                                                    )}
                                                </div>    
                                            </Col>
                                            <Col xs={3}>
                                                <span className='filter-icon' onClick={(e) => {handleLocationClear(); e.stopPropagation()}}>
                                                    <IoCloseOutline />
                                                </span>
                                            </Col>
                                        </Row>
                                    )}
                                </div>
                            </Accordion.Header>
                            <Accordion.Body>
                                <LocationDropdown 
                                    showDropdown={true} 
                                    setShowDropdown={setShowLocationDropdown} 
                                    searchTerm={searchTerm}
                                    setSearchTerm={setSearchTerm}
                                />
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="2">
                            <Accordion.Header>
                                <div className='w-100 d-flex justify-content-between'>
                                    <div>{t('who')}</div>
                                    {totalGuests === 0 ? (
                                        <span className="filter-text">{t('whoever')}</span>
                                    ) : (
                                        <Row className='filter-text'>
                                            <Col xs={7}>
                                            <span>{totalGuests}</span>
                                            </Col>
                                            <Col xs={5}>
                                                <span className='filter-icon' onClick={(e) => {handleGuestsClear(); e.stopPropagation()}}><IoCloseOutline /></span>
                                            </Col>
                                        </Row>
                                    )}
                                </div>
                            </Accordion.Header>
                            <Accordion.Body>
                                <GuestsDropdown
                                    showDropdown={true}
                                    setShowDropdown={setShowGuestsDropdown}
                                    totalGuests={totalGuests}
                                />
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </Modal.Body>
                <Modal.Footer>
                    {/* Button to clear all filters */}
                    <Button className='clear-filters-btn' onClick={() => {
                        handleDateClear(); 
                        handleLocationClear(); 
                        handleGuestsClear(); 
                        handleCloseModal();
                    }}>
                        {t('clear_filters')}
                    </Button>
                    {/* Button to close the modal and apply filters */}
                    <Button className='search-filters-btn' onClick={handleCloseModal}>
                    {t('search')}
                    </Button>
                </Modal.Footer>
            </Modal>       
        </div>
    );
};

export default FilterBar;