import React, { createContext, useState } from 'react';

// Create the context
export const FiltersContext = createContext();

/**
 * FiltersProvider Component
 * 
 * The FiltersProvider component provides a context to manage and share filtering-related state across 
 * different parts of the application. It uses React's Context API to make filtering data accessible 
 * to all components within its provider. This is useful for managing search filters, booking details, 
 * or any other type of state that needs to be shared across multiple components.
 * 
 * Key Features:
 * 
 * 1. **Centralized State Management**:
 *    - Manages various filtering states including selected states, number of adults, children, infants, pets, 
 *      and date ranges.
 * 
 * 2. **Context API Integration**:
 *    - Uses React's Context API to provide the state and setter functions to the entire component tree.
 * 
 * 3. **State Variables**:
 *    - `selectedStates`: Array of selected states (e.g., locations, regions) for filtering.
 *    - `adults`: Number of adults for the search or booking.
 *    - `childrens`: Number of children for the search or booking.
 *    - `infants`: Number of infants for the search or booking.
 *    - `pets`: Number of pets for the search or booking.
 *    - `dates`: Object containing `checkIn` and `checkOut` dates.
 * 
 * Props:
 * - `children`: The components that will have access to the context values.
 */
export const FiltersProvider = ({ children }) => {
    // State to manage selected states
    const [selectedStates, setSelectedStates] = useState([]);
    
    // State to manage number of adults
    const [adults, setAdults] = useState(0);
    
    // State to manage number of children
    const [childrens, setChildrens] = useState(0);
    
    // State to manage number of infants
    const [infants, setInfants] = useState(0);
    
    // State to manage number of pets
    const [pets, setPets] = useState(0);
    
    // State to manage check-in and check-out dates
    const [dates, setDates] = useState({ checkIn: null, checkOut: null });

    return (
        <FiltersContext.Provider value={{ 
            selectedStates, setSelectedStates, 
            adults, setAdults,
            childrens, setChildrens,
            infants, setInfants,
            pets, setPets,
            dates, setDates
        }}>
            {children}
        </FiltersContext.Provider>
    );
};