import React from 'react';
import { Modal } from 'react-bootstrap';
import './AboutUsModal.css'; // Import the CSS file for styling
import { useTranslation } from 'react-i18next';
import logo from '../../assets/DVC Apartments.png';

/**
 * AboutUsModal Component
 * 
 * This component renders a modal for displaying simple text content.
 */

const AboutUsModal = ({ show, handleClose }) => {

    const { t } = useTranslation(); // Initialize translation hook for multi-language support

    return (
        <Modal className='aboutUs-modal' show={show} onHide={handleClose} centered>
            <Modal.Header closeButton>
                <div className='logo-container'>
                    <img src={logo} alt="logo DVC" width={100}/>
                </div>
                <Modal.Title>{t('about_dvc_title')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{t('about_dvc_text')}</Modal.Body>
        </Modal>
    );
};

export default AboutUsModal;