import React, { useRef, useEffect, useContext, useState } from 'react';
import { Dropdown, FormControl, Row, Col, Button } from 'react-bootstrap';
import './LocationDropdown.css';
import { FiltersContext } from '../../../../context/FiltersContext';
import { useTranslation } from 'react-i18next';
import { useApartments } from '../../../../context/ApartmentsContext';

/**
 * LocationDropdown Component
 * 
 * The LocationDropdown component allows users to select locations (cities) from a dropdown menu. 
 * It includes a search functionality to filter through cities and supports multi-selection of cities.
 * 
 * Key Features:
 * 
 * 1. **Search Functionality**:
 *    - Users can filter cities based on the search term.
 * 
 * 2. **Selection Toggle**:
 *    - Users can select or deselect cities.
 * 
 * 3. **Click Outside to Close**:
 *    - Closes the dropdown when clicking outside of it.
 * 
 * 4. **Responsive Buttons**:
 *    - Includes buttons to clear selections and proceed.
 * 
 * Props:
 * - `showDropdown`: Boolean indicating if the dropdown is visible.
 * - `setShowDropdown`: Function to control the visibility of the dropdown.
 * - `searchTerm`: Current value of the search input.
 * - `setSearchTerm`: Function to update the search term.
 * - `toggleGuestsDropdown`: Function to toggle the guests dropdown.
 */

const LocationDropdown = ({ showDropdown, setShowDropdown, searchTerm, setSearchTerm, toggleGuestsDropdown }) => {
    const { t } = useTranslation();
    // Use context to get and set the selected states
    const { selectedStates, setSelectedStates } = useContext(FiltersContext);

    const { apartments } = useApartments();

    // useState to store the list of states (cities)
    const [states, setStates] = useState([]);
    
    // useRef to reference the dropdown container
    const dropdownRef = useRef(null);

    // useEffect to extract unique cities from the apartments data and set the states
    useEffect(() => {
        const uniqueCities = [...new Set(apartments.map(apartment => apartment.city))];
        setStates(uniqueCities);
    }, [apartments]);

    // useEffect to handle clicks outside the dropdown to close it
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setShowDropdown(false);
            }
        };

        if (showDropdown) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [showDropdown, setShowDropdown]);

    // Filter the states based on the search term
    const filteredStates = states.filter(state =>
        state.toLowerCase().includes(searchTerm.toLowerCase())
    );

    // Function to handle the selection of a state
    const handleStateClick = (state) => {
        setSelectedStates(prevSelectedStates => {
            if (prevSelectedStates.includes(state)) {
                return prevSelectedStates.filter(s => s !== state);
            } else {
                return [...prevSelectedStates, state];
            }
        });
    };

    // Function to handle the click of the "Continue" button
    const handleContinueClick = (event) => {
        event.stopPropagation();
        setShowDropdown(false);
        toggleGuestsDropdown();
    };

    return (
        <div ref={dropdownRef} className="location-dropdown">
            {/* Dropdown Header with Search Input */}
            <div className="dropdown-header px-4 mt-lg-3">
                <span>{t('where_to')}?</span>
                <FormControl
                    autoFocus
                    className="search-input mt-2 rounded-3"
                    placeholder={t('search_by_cities')}
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                />
            </div>
            {/* Dropdown Options List */}
            <div className="dropdown-options px-4 py-2">
                <Row>
                    {filteredStates.map((state, index) => (
                        <Col key={index} xs={6}>
                            <Dropdown.Item 
                                onClick={() => handleStateClick(state)}
                                className={selectedStates.includes(state) ? 'selected rounded-3' : 'rounded-3'}
                            >
                                {state}
                            </Dropdown.Item>
                        </Col>
                    ))}
                </Row>
            </div>
            {/* Action Buttons */}
            <Row className="mt-2 px-4 justify-content-between d-none d-lg-flex">
                <Col xs="auto">
                    <Button className='rounded-3 clear-btn' onClick={() => {
                        setSearchTerm('');
                        setSelectedStates([]);
                        setShowDropdown(false);
                    }}>{t('clear')}</Button>
                </Col>
                <Col xs="auto">
                    <Button className='rounded-3 continue-btn' disabled={selectedStates.length === 0} onClick={handleContinueClick}>{t('continue')}</Button>
                </Col>
            </Row>
        </div>
    );
};

export default LocationDropdown;